import Random from '../../utils/Random';

import {
  Sum, Product, Quotient,
} from '../utils/math';
import * as math from '../utils/math';
import { exoComponents } from './utils/exoComponents';

export default function (seed) {
  const random = new Random(seed);

  const { shuffle, randomNegation } = math.bindRandomizedFunctions(random)
  
  const calculation = shuffle(Sum(
    Product(
      random.integer({ min: 2, max: 9 }),
      Quotient(random.integer({ min: 10, max: 40 }), random.integer({ min: 2, max: 9 })),
    ),
    randomNegation(Quotient(random.integer({ min: 10, max: 40 }), random.integer({ min: 2, max: 9 }))),
  ))

  return exoComponents(calculation);
}
