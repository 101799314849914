import React from 'react';

export default function(functions ,kwargs={}) {
  const margin = 20;
  const graphRegion = kwargs.graphRegion || {
    x: {min: 0, max: 15},
    y: {min: 0, max: 10}
  }
  const pxPerUnit = 30;
  const viewBox = {
    width: (graphRegion.x.max - graphRegion.x.min) * pxPerUnit + 2*margin,
    height: (graphRegion.y.max - graphRegion.y.min) * pxPerUnit + 2*margin,
  }

  const svgCoord = {
    x: (x) => margin + x*pxPerUnit,
    y: (y) => viewBox.height - margin - y*pxPerUnit,
  }

  const Line = ({ from, to, dashed, ...props }) => (
    <line
      x1={svgCoord.x(from[0])}
      y1={svgCoord.y(from[1])}
      x2={svgCoord.x(to[0])}
      y2={svgCoord.y(to[1])}
      stroke="black"
      strokeDasharray={ dashed ? "4": "none" }
      {...props}
    />
  )  

  const HorizontalLine = ({ y, ...props }) => (
    <Line
      from={[0, y]}
      to={[graphRegion.x.max, y]}
      {...props}
    />
  )
  const VerticalLine = ({ x, ...props }) => (
    <Line
      from={[x, 0]}
      to={[x, graphRegion.y.max]}
      {...props}
    />
  )

  const styles = [
    '20 6',
    '8',
    '14 6 2 6',
  ]

  const functionsGraph = functions.map((F, i) => (
    F({
      svgCoord,
      graphRegion,
      stroke: 'black',
      strokeWidth: '1.2',
      strokeDasharray: functions.length > 1 ? styles[i] : 'none',
    })
  ))
    
  return (
      <svg
        viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}
        xmlns="http://www.w3.org/2000/svg"
      >
      <defs>
        <marker style={{"overflow":"visible"}} id="ArrowEnd" refX="0" refY="0" orient="auto">
          <path
             transform="matrix(-1.1,0,0,-1.1,-1.1,0)"
             d="M 8.7185878,4.0337352 -2.2072895,0.01601326 8.7185884,-4.0017078 c -1.7454984,2.3720609 -1.7354408,5.6174519 -6e-7,8.035443 z"
             style={{"fill":"#000000","fillOpacity":"1","fillRule":"evenodd","stroke":"#000000","strokeWidth":"0.625","strokeLinejoin":"round","strokeOpacity":"1"}}
             id="path872"
          />
        </marker>
      </defs>

      <Line
        from={[0, 0]}
        to={[0, graphRegion.y.max]}
        style={{"markerEnd":"url(#ArrowEnd)"}}
      />
      <Line
        from={[0, 0]}
        to={[graphRegion.x.max, 0]}
        style={{"markerEnd":"url(#ArrowEnd)"}}
      />

      <text x={0} y={0} dominantBaseline="hanging">y</text>
      <text x={svgCoord.x(graphRegion.x.max)} y={svgCoord.y(0)} dominantBaseline="hanging">x</text>

      {
        range(Math.floor(graphRegion.y.max-graphRegion.y.min), 1).map((i) => {
          const ordinate = i;
          return (
            <>
              <HorizontalLine y={ordinate} dashed={true} strokeWidth="0.5px" stroke="#888"/>
              {(ordinate !== graphRegion.y.max) &&
                <text
                  x={svgCoord.x(0)-(margin/4)}
                  y={svgCoord.y(ordinate)}
                  dominantBaseline="middle"
                  textAnchor="end"
                  style={{'fontSize': `${margin*0.6}px`}}
                >
                {ordinate}
                </text>
              }
            </>
          )
        })
      }

      {
        range(Math.floor(graphRegion.x.max-graphRegion.x.min), 1).map((abscissa) => (
          <>
            <VerticalLine x={abscissa} dashed={true}  strokeWidth="0.5px" stroke="#888"/>
            {(abscissa !== graphRegion.x.max) &&
              <text
                y={svgCoord.y(0)+(margin/4)}
                x={svgCoord.x(abscissa)}
                dominantBaseline="hanging"
                textAnchor="middle"
                style={{'fontSize': `${margin*0.6}px`}}
              >
                {abscissa}
              </text>
            }
          </>
        ))
      }
      
      {functionsGraph}

      {functionsGraph.map((fGraph, i) => (
        // XXX assumes all functions or no functions have a label
        fGraph.props.label && (
          <>
            <text
              y={margin*3/4}
              x={30 + 100*i}
              style={{'fontSize': `${margin*0.6}px`}}
            >
              {fGraph.props.label || '??'}
            </text>
            <line
              x1={30 + 100*i + 15}
              y1={margin/2}
              x2={30 + 100*(i+1) - 50}
              y2={margin/2}
              stroke="black"
              strokeDasharray={styles[i]}
            />
          </>
        )
      ))}
    </svg>
  )
}

function range(size, startAt = 0) {
  return [...Array(size).keys()].map(i => i + startAt);
}
  