import Random from '../../utils/Random';

import {
  Sum, Product, Quotient,
} from '../utils/math';
import * as math from '../utils/math';
import { exoComponents } from './utils/exoComponents';

export default function arithmeticQ1(seed) {
  const random = new Random(seed);

  const { shuffle, randomNegation } = math.bindRandomizedFunctions(random)

  const calculation = shuffle(Sum(
    Quotient(randomNegation(random.integer({ min: 10, max: 19 })), random.integer({ min: 2, max: 9 })),
    Product(
      Sum(random.integer({ min: 12, max: 21 }), randomNegation(random.integer({ min: 5, max: 11 }))),
      random.integer({ min: 2, max: 8 }),
    ),
  ));

  return exoComponents(calculation);
}
