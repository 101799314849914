export default (object) => Object.fromEntries(functionsPath(object).map(([path, f]) => (
  [path.join('.'), f]
)))

const functionsPath = (object, prefix=[]) => [].concat(
  ...Object.entries(object).map(([key, value]) => {
    const path = prefix.concat([key]);
    return typeof value === 'function'
      ? [[path, value]]
      : functionsPath(value, path)
  })
)
