// Random number generation and applications (random boolean, subsets...)
import seedrandom from 'seedrandom';

export default class Random {
  constructor(seed) {
    this.rng = new seedrandom(seed)
  }

  integer({ min=0, max, exclude=[] }) {
    // removing non-integer values
    exclude = exclude.filter((x) => Number.isInteger(x))

    // Removing duplicates and sorting
    // note that by default javascript sorts *alphabetically* (gasp!)
    exclude = [ ...new Set(exclude)].sort((a, b) => a - b)

    const shiftedMax = max - exclude.length;

    if (min > shiftedMax) {
      throw new Error(`min (${min}) greater than max (${max} - ${exclude.length})`)
    }
    const generated = Math.ceil(min) + Math.floor(this.rng()*(shiftedMax-min))

    let shiftAmount = 0;
    for (const excluded of exclude) {
      if (excluded - shiftAmount - 1 < generated) {
        shiftAmount = shiftAmount + 1;
      } else {
        break;
      }
    }

    return generated + shiftAmount;
  }

  choice(options) {
    return options[this.integer({ min: 0, max: options.length })]
  }

  /**
   * Shuffle an array in-place and return the array.
   * 
   * Taken from https://stackoverflow.com/a/2450976/3025740
   * @param {Array} array 
   */
  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = this.integer({ max: currentIndex })
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  boolean() {
    if (Math.floor(this.rng()*2) === 1) {
      return true;
    } else {
      return false;
    }
  }
}