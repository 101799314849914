import React from 'react';
import TeX from '@matejmazur/react-katex';

import Random from "./utils/Random";
import { Typography } from "@material-ui/core";

const TOP_RIGHT = 'TOP RIGHT';
const TOP_LEFT = 'TOP LEFT';
const BOTTOM_LEFT = 'BOTTOM LEFT';
const BOTTOM_RIGHT = 'BOTTOM RIGHT';

const CORNERS = [
  TOP_RIGHT,
  TOP_LEFT,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
]

class Angle {
  constructor(radians) {
    this.degrees = Math.round(radians * (180 / Math.PI))
  }

  toString() {
    return `${this.degrees}°`
  }
}

class Length {
  constructor(value) {
    this.value = value
  }

  toString() {
    return `${this.value} \\text{ cm}`
  }
}

function randomRightTriangle(random) {
  const width = random.integer({ min: 200, max: 400 });
  const height = random.integer({ min: 80, max: 250 });

  const hypothenuse = Math.round(Math.hypot(width, height));
  const angleAtHeight = Math.atan(width/height);
  const angleAtWidth = Math.atan(height/width);

  return {
    height: new Length(height),
    width: new Length(width),
    hypothenuse: new Length(hypothenuse),
    angleAtHeight: new Angle(angleAtHeight),
    angleAtWidth: new Angle(angleAtWidth),
  };
}

function namePointsAtRandom(random, corners) {
  const names = random.shuffle(['A', 'B', 'C']);

  return Object.fromEntries(
    corners.map((c, i) => [c, names[i]])
  )
}

function trigonometry(seed) {
  const random = new Random(seed);

  const triangle = randomRightTriangle(random);

  const positionRightAngle = random.choice(CORNERS)

  const missingCorner = CORNERS[
    (CORNERS.indexOf(positionRightAngle) + 2) % 4
  ]

  const pointNames = namePointsAtRandom(
    random,
    CORNERS.filter((c) => c !== missingCorner)
  )

  const known1 = random.choice(['height', 'width', 'hypothenuse'])
  const known2 = random.choice(['height', 'width', 'hypothenuse', 'angleAtWidth', 'angleAtHeight'].filter(
    (value) => value !== known1,
  ))
  const known = [known1, known2];

  const measurementsNames = {
    width: positionRightAngle.includes('TOP')
      ? `${pointNames[TOP_LEFT]}${pointNames[TOP_RIGHT]}`
      : `${pointNames[BOTTOM_LEFT]}${pointNames[BOTTOM_RIGHT]}`,
    height: positionRightAngle.includes('LEFT')
    ? `${pointNames[BOTTOM_LEFT]}${pointNames[TOP_LEFT]}`
    : `${pointNames[BOTTOM_RIGHT]}${pointNames[TOP_RIGHT]}`,
  }

  if (positionRightAngle === TOP_LEFT) {
    measurementsNames.hypothenuse = `${pointNames[BOTTOM_LEFT]}${pointNames[TOP_RIGHT]}`;
    measurementsNames.angleAtHeight = `\\widehat{${(
      pointNames[TOP_RIGHT]
      + pointNames[BOTTOM_LEFT]
      + pointNames[TOP_LEFT]
    )}}`;
    measurementsNames.angleAtWidth = `\\widehat{${(
      pointNames[TOP_LEFT]
      + pointNames[TOP_RIGHT]
      + pointNames[BOTTOM_LEFT]
    )}}`;
  } else if (positionRightAngle === TOP_RIGHT) {
    measurementsNames.hypothenuse = `${pointNames[TOP_LEFT]}${pointNames[BOTTOM_RIGHT]}`;
    measurementsNames.angleAtHeight = `\\widehat{${(
      pointNames[TOP_RIGHT]
      + pointNames[BOTTOM_RIGHT]
      + pointNames[TOP_LEFT]
    )}}`;
    measurementsNames.angleAtWidth = `\\widehat{${(
      pointNames[TOP_RIGHT]
      + pointNames[TOP_LEFT]
      + pointNames[BOTTOM_RIGHT]
    )}}`;
  } else if (positionRightAngle === BOTTOM_LEFT) {
    measurementsNames.hypothenuse = `${pointNames[TOP_LEFT]}${pointNames[BOTTOM_RIGHT]}`;
    measurementsNames.angleAtHeight = `\\widehat{${(
      pointNames[BOTTOM_RIGHT]
      + pointNames[TOP_LEFT]
      + pointNames[BOTTOM_LEFT]
    )}}`;
    measurementsNames.angleAtWidth = `\\widehat{${(
      pointNames[TOP_LEFT]
      + pointNames[BOTTOM_RIGHT]
      + pointNames[BOTTOM_LEFT]
    )}}`;
  } else if (positionRightAngle === BOTTOM_RIGHT) {
    measurementsNames.hypothenuse = `${pointNames[BOTTOM_LEFT]}${pointNames[TOP_RIGHT]}`;
    measurementsNames.angleAtHeight = `\\widehat{${(
      pointNames[BOTTOM_LEFT]
      + pointNames[TOP_RIGHT]
      + pointNames[BOTTOM_RIGHT]
    )}}`;
    measurementsNames.angleAtWidth = `\\widehat{${(
      pointNames[TOP_RIGHT]
      + pointNames[BOTTOM_LEFT]
      + pointNames[BOTTOM_RIGHT]
    )}}`;
  }


  const viewBox = {
    width: 450,
    height: 300,
  }
  const margin = 20;

  const width = triangle.width.value;
  const height = triangle.height.value;

  const toFind =  Object.fromEntries(
    Object.entries(measurementsNames).filter(
      ([measurement, name]) => (!known.includes(measurement))
    )
  )

  return {
    Instructions: (
      <>
        <Typography>
          {`Dans le triangle `}<TeX>ABC</TeX>
          {` rectangle en `}<TeX>{`${pointNames[positionRightAngle]}`}</TeX>
          {` représenté ci-dessous, on a `}
          <TeX>{`${measurementsNames[known1]} = ${triangle[known1]}`}</TeX>
          {` et `}<TeX>{`${measurementsNames[known2]} = ${triangle[known2]}`}</TeX>{` . `}
          {`Touver `}<TeX>{`${Object.values(toFind).slice(0, -1).join(', ')}`}</TeX>
          {`, et `}<TeX>{`${Object.values(toFind).slice(-1)}`}</TeX>{' .'}
        </Typography>
        <svg
          viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            points={
              Object.keys(pointNames).map((corner) => {
                switch (corner) {
                  case TOP_LEFT:
                    return `${margin},${margin}`;
                  case TOP_RIGHT:
                    return `${width+margin},${margin}`;
                  case BOTTOM_RIGHT:
                    return `${width+margin},${height+margin}`;
                  case BOTTOM_LEFT:
                    return `${margin},${height+margin}`;
                  default:
                    throw Error('bad corner', corner);
                }
              }).join(' ')
            }
            fill="none"
            stroke="black"
          />
          {Object.entries(pointNames).map(([corner, name]) => (
            <text
              y={corner.includes('TOP') ? margin : margin+height}
              x={corner.includes('LEFT') ? margin : margin+width}
              dominantBaseline={corner.includes('TOP') ? 'baseline' : 'hanging'}
              textAnchor={corner.includes('LEFT') ? 'end' : 'start'}
              style={{'fontSize': `${margin}px`}}
              key={corner}
            >
              {name}
            </text>
          ))}
        </svg>
      </>
    ),
    Solution: (
      <ul>
        {Object.entries(toFind).map(([measurement, name]) => (
          <li key={measurement}><TeX>{`${name} = ${triangle[measurement]}`}</TeX></li>
        ))}
      </ul>
    ),
  };
}

trigonometry.title = 'Mesures dans le triangle rectangle'
export default {
  trigonometry,
}