import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function ExoSelect({ currentExoKey, onChange, exercises }) {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Exercice</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={currentExoKey}
        onChange={onChange}
      >
        {
          Object.entries(exercises).map(([key, exercise]) => (
            <MenuItem value={key} key={key}>{exercise.title}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}
