import Random from '../../utils/Random';

import {
  Sum, Product,
} from '../utils/math';
import * as math from '../utils/math';
import { exoComponents } from './utils/exoComponents';

export default function arithmeticZ1(seed) {
  const random = new Random(seed);

  const { shuffle, randomNegation } = math.bindRandomizedFunctions(random)

  const calculation = shuffle(Sum(
    Product(random.integer({ min: 2, max: 9 }), randomNegation(random.integer({ min: 10, max: 19 }))),
    randomNegation(random.integer({ min: 10, max: 19 })),
  ));

  return exoComponents(calculation);
}
