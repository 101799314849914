import Random from '../../utils/Random';

import {
  Sum, Product,
  Variable,
  evaluate, substitute
} from '../utils/math';
import * as math from '../utils/math';
import { exoComponents } from './utils/exoComponents';

export default function deg1lvl1(seed) {
  const random = new Random(seed);

  const { shuffle, randomNegation } = math.bindRandomizedFunctions(random)

  const unknown = Variable('x')

  const left = shuffle(Sum(
    randomNegation(random.integer({ min: 1, max: 20 })),
    randomNegation(Product(
      random.integer({ min: 2, max: 9 }),
      unknown,
    ))
  ))

  const solution = random.integer({ min: 1, max: 6 })

  const right = evaluate(substitute(left, 'x', solution));

  return exoComponents({ left, right, unknown, solution });
}
