import Random from '../../utils/Random';

import {
  Sum, Product, Minus,
  Variable,
  evaluate, substitute
} from '../utils/math';
import * as math from '../utils/math';
import { exoComponents } from './utils/exoComponents';

export default function deg1lvl1(seed) {
  const random = new Random(seed);

  const { shuffle, randomNegation } = math.bindRandomizedFunctions(random)

  const unknown = Variable('x')

  const left = shuffle(
    Product(
      randomNegation(random.integer({ min: 2, max: 7 })),
      Sum(
        randomNegation(random.integer({ min: 1, max: 9 })),
        randomNegation(Product(
          random.integer({ min: 2, max: 6 }),
          unknown,
        ))
      )
    )
  )

  const partialRight = Product(
    random.integer({ min: 2, max: 9 }),
    unknown,
  )

  const solution = random.integer({ min: 1, max: 6 })

  const right = shuffle(
    Sum(
      partialRight,
      evaluate(substitute(Sum(left, Minus(partialRight)), 'x', solution))
    )
  );

  return exoComponents({ left, right, unknown, solution });
}
