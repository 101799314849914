export function Sum(left, right) {
  return {
    name: 'Sum',
    left,
    right,
  };
}

export function Product(left, right) {
  return {
    name: 'Product',
    left,
    right,
  };
}

export function Minus(expression) {
  return {
    name: 'Minus',
    operand: expression,
  };
}

export function Quotient(numerator, denominator) {
  return {
    name: 'Quotient',
    numerator,
    denominator,
  };
}

export function Variable(symbol) {
  return {
    name: 'Variable',
    symbol,
  };
}

export function Equality(left, right) {
  return {
    name: 'Equality',
    left,
    right,
  };
}

function stringOfQuotient(quotient) {
  const num = stringify(quotient.numerator);
  const den = stringify(quotient.denominator);

  return `\\frac{${num}}{${den}}`;
}

function stringOfSum(sum) {
  let left = stringify(sum.left);
  let right = stringify(sum.right);
  
  let symbol = '-';
  if (Number.isInteger(sum.right) && sum.right < 0) {
    right = stringify(-sum.right);
  } else if (sum.right.name === 'Minus') {
    if (Number.isInteger(sum.right.operand)) {
      right = stringify(sum.right.operand);
    } else {
      right = '(' + stringify(sum.right.operand) + ')';
    }
  } else {
    symbol= '+';
    if (['-'].includes(right[0])) {
      right = `(${right})`;
    }
  }

  return `${left} ${symbol} ${right}`;
}

function stringOfProduct(product) {
  let left = stringify(product.left);
  let right = stringify(product.right);

  // Implicit multiplication
  if (product.left.name === 'Variable' && Number.isInteger(product.right)) {
    return `${right}${left}`;
  }
  if (Number.isInteger(product.left) && product.right.name === 'Variable') {
    return `${left}${right}`;
  }

  if (product.left.name === 'Sum') {
    left = `(${left})`;
  }

  if (['Minus', 'Sum'].includes(product.right.name)) {
    right = `(${right})`;
  }

  return `${left} × ${right}`;
}

export function stringify(expression) {
  switch (expression.name) {
    case 'Sum':
      return stringOfSum(expression);
    case 'Product':
      return stringOfProduct(expression);
    case 'Minus':
      return expression.operand.name ? `-(${stringify(expression.operand)})` : `-${stringify(expression.operand)}`;
    case 'Quotient':
      return stringOfQuotient(expression);
    case 'Equality':
      return stringify(expression.left) + ' = ' + stringify(expression.right);
    case 'Variable':
      return expression.symbol;
    default:
      return String(expression);
  }
}

function castQuotient(intOrQuotient) {
  if (Number.isInteger(intOrQuotient)) {
    return Quotient(intOrQuotient, 1);
  } else if (intOrQuotient.name === 'Quotient') {
    return intOrQuotient;
  } else if (intOrQuotient.name === 'Minus' && intOrQuotient.operand.name === 'Quotient') {
    const { numerator, denominator } = intOrQuotient.operand;
    if (!(Number.isInteger(numerator) && Number.isInteger(denominator))) {
      throw Error(`quotient must contain integers: ${intOrQuotient}`);
    }
    return Quotient(-numerator, denominator);
  } else {
    throw Error(`neither an int nor a quotient: ${intOrQuotient}`);
  }
}

/**
 * Greatest common divisor
 */
function gcd(x, y) {
  if (x < 0 || y < 0) {
    throw Error('GCD requires non-negative numbers');
  }

  let r1 = x;
  let r2 = y;
  let r_tmp;
  while (r2 !== 0) {
    r_tmp = r1 % r2;
    r1 = r2;
    r2 = r_tmp;
  }
  return r1;
}

/**
 * Least common multiple
 */
function lcm(x, y) {
  if (x < 0 || y < 0) {
    throw Error('GCD requires non-negative numbers');
  }

  return (x*y) / gcd(x, y);
}

export function simplify(expression) {
  switch (expression.name) {
    case 'Quotient':
      const { numerator, denominator } = expression;
      const g = gcd(Math.abs(numerator), Math.abs(denominator));
      if (g === Math.abs(denominator)) {
        return numerator/denominator;
      }
      return Quotient(numerator/g, denominator/g);
    default:
      throw Error(`Simplification not implemented for this expression: ${expression}`)
  }
}

function evalProduct(product) {
  let left = evaluate(product.left);
  let right = evaluate(product.right);

  if (Number.isInteger(left) && Number.isInteger(right)) {
    return left * right;
  } else {
    left = castQuotient(left);
    right = castQuotient(right);

    const denominator = left.denominator * right.denominator;
    const numerator = left.numerator * right.numerator;

    return simplify(Quotient(numerator, denominator))
  }
}

function evalNegation(negation) {
  const operand = evaluate(negation.operand);
  if (typeof operand === 'number') {
    return - operand;
  } else {
    return Minus(operand);
  }
}

export function evaluate(expression) {
  switch (expression.name) {
    case 'Sum':
      let left = evaluate(expression.left);
      let right = evaluate(expression.right);

      if (Number.isInteger(left) && Number.isInteger(right)) {
        return left + right;
      } else {
        left = castQuotient(left);
        right = castQuotient(right);

        const denominator = lcm(Math.abs(left.denominator), Math.abs(right.denominator));
        const numerator = (
          left.numerator * (denominator / left.denominator)
          + right.numerator * (denominator / right.denominator)
        )

        return simplify(Quotient(numerator, denominator))
      }
    case 'Product':
      return evalProduct(expression);
    case 'Minus':
      return evalNegation(expression);
    case 'Quotient':
      const { numerator, denominator } = expression;
      return simplify(Quotient(evaluate(numerator), evaluate(denominator)));
    case 'Variable':
      throw Error('cannot evaluate variable; must do a substitution first');
    case 'Equality':
      throw Error('cannot evaluate Equality');
    default:
      return expression;
  }
}

export function substitute(expression, symbol, replacement) {
  switch (expression.name) {
    case 'Variable':
      if (expression.symbol === symbol) {
        return replacement;
      } else {
        return expression;
      }
    case 'Product':
      return Product(
        substitute(expression.left, symbol, replacement),
        substitute(expression.right, symbol, replacement),
      )
    case 'Sum':
      return Sum(
        substitute(expression.left, symbol, replacement),
        substitute(expression.right, symbol, replacement),
      )
    case 'Minus':
      return Minus(substitute(expression.operand, symbol, replacement));
    case 'Quotient':
      return Quotient(
        substitute(expression.numerator, symbol, replacement),
        substitute(expression.denominator, symbol, replacement),
      )
    default:
      return expression;
  }
}

export function shuffle(random, expression) {
  if (expression.name === 'Equality') {
    throw Error('shuffling of equality not implemented');
  }
  if (['Sum', 'Product'].includes(expression.name)) {
    const left = shuffle(random, expression.left);
    const right = shuffle(random, expression.right);

    const doShuffle = random.boolean();

    if (doShuffle) {
      return {
        ...expression,
        left: right,
        right: left,
      };
    } else {
      return {
        ...expression,
        left,
        right,
      };
    }
  } else {
    return expression;
  }
}

export const randomNegation = (random, expression) => random.boolean() ? Minus(expression) : expression;

export const bindRandomizedFunctions = (random) => ({
  shuffle: (...args) => shuffle(random, ...args),
  randomNegation: (...args) => randomNegation(random, ...args),
})