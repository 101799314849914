import Random from '../../utils/Random';

import {
  Sum, Product,
} from '../utils/math';
import * as math from '../utils/math';
import { exoComponents } from './utils/exoComponents';

export default function (seed) {
  const random = new Random(seed);

  const { shuffle, randomNegation } = math.bindRandomizedFunctions(random)

  const calculation = shuffle(Product(
    Sum(random.integer({ min: 2, max: 9 }), random.integer({ min: 10, max: 19 })),
    Sum(
      Product(randomNegation(random.integer({ min: 2, max: 8 })), random.integer({ min: 7, max: 12 })),
      randomNegation(random.integer({ min: 10, max: 30 })),
    ),
  ))

  return exoComponents(calculation);
}
