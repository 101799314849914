import React from 'react';
import TeX from '@matejmazur/react-katex';
import Typography from '@material-ui/core/Typography';

import Random from '../utils/Random';

import graph from './utils/graph';
import LinearFunction from './utils/LinearFunction'
import { Quotient, stringify, evaluate } from '../algebra/utils/math';

class RandomLinearFunctionsParamList {
  constructor({ random, graphRegion }) {
    this.random = random;
    this.graphRegion = graphRegion;

    this.items = [];
  }

  add({ increasing }) {
    const xMax = this.graphRegion.x.max;
    const yMax = this.graphRegion.y.max;

    const ordinateAtZero = this.random.integer(
      increasing
      ? { min: 0, max: yMax/2 + 1 }
      : { min: yMax/2 - 1, max: yMax }
    )

    const slope = [
      this.random.integer({ min: 1, max: 3 }),
      null,
    ]

    // Forbidden values for slope[1]
    const exclude = [];
    this.items.forEach((item) => {
      if (item.from[1] === ordinateAtZero) {
        // this is to take into account that 2/8 is the same slope as 1/4
        if ((slope[0] * item.slope[1]) % item.slope[0] === 0) {
          exclude.push(Math.abs((slope[0] * item.slope[1]) / item.slope[0]));
        }

        // In case of a very low slope
        // we want to exclude having a slope that it too similar as well
        if (
          Math.abs(item.slope[0] / item.slope[1]) < (1/8)
          && item.slope[0] === (slope[0] * (increasing ? 1 : -1))
        ) {
          exclude.push(item.slope[1] - 1, item.slope[1] + 1)
        }
      }
    })

    const slope_denom_args = {
      min: (
        increasing
          ? (slope[0] * xMax) / (yMax - ordinateAtZero)
          : (slope[0] * xMax) / ordinateAtZero
      ),
      max: xMax - 2,
      exclude,
    }

    slope[1] = this.random.integer(slope_denom_args)

    if (increasing === false) {
      slope[0] = -slope[0];
    }  

    this.items.push({
      from: [ 0, ordinateAtZero ],
      slope,
    })
  
  
  }
}

// XXX assumes f.from[0] == 0
// XXX not the full equation
const latexFromFunction = (f) => `${stringify(evaluate(Quotient(f.slope[0], f.slope[1])))} x ~+~ ${f.from[1]}`

export default function(seed) {
  const random = new Random(seed);

  const graphRegion = {
    x: {min: 0, max: 15},
    y: {min: 0, max: 10}
  }

  const paramsList = new RandomLinearFunctionsParamList({ random, graphRegion });
  paramsList.add({ increasing: true })
  paramsList.add({ increasing: random.boolean() })
  paramsList.add({ increasing: false })

  const firstIsF = random.boolean();
  paramsList.items[0].label = firstIsF ? 'f' : 'h';
  paramsList.items[1].label = 'g';
  paramsList.items[2].label = firstIsF ? 'h' : 'f';

  const givenEquation = latexFromFunction(paramsList.items[random.integer({ min: 0, max: 2 })]);

  return {
    Instructions: (
      <>
        <Typography>
          {"Trouver quelle fonction a pour équation "}
          <TeX>{'y = ' + givenEquation}</TeX>
          {" et trouver l'équation des deux autres."}
        </Typography>
        {graph(
          paramsList.items.map((params) => (
            LinearFunction(params)
          )),
        )}
      </>
    ),
    Solution: (<ul>
      {paramsList.items.map((fn) => (
        <li key={fn.label}><TeX>{`${fn.label}(x) = ` + latexFromFunction(fn)}</TeX></li>
      )

      )}
    </ul>),
  }
}