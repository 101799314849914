import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

import 'katex/dist/katex.min.css';

import ExoSelect from './ExoSelect';

import * as exoModule from './exercices';
import listFunctionsPathDeep from './utils/listFunctionsPathDeep';

const exoPaths = listFunctionsPathDeep(exoModule);

const randomHex = () => Buffer.from(crypto.getRandomValues(new Uint8Array(8))).toString('hex');

const useStyles = makeStyles((theme) => ({
  h1: {
    textAlign: 'center',
    marginTop: '0.5em',
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  }
}));

function App() {
  const classes = useStyles();

  const [currentExo, setCurrentExo] = React.useState('algebra.arithmeticQ1');
  const [seed, setSeed] = React.useState(randomHex());
  const [showSolution, setShowSolution] = React.useState(false);

  const changeCurrentExo = (event) => {
    setCurrentExo(event.target.value);
    setShowSolution(false);
  }

  const regenerate = () => {
    setSeed(randomHex());
    setShowSolution(false);
  }

  const { Instructions, Solution } = exoPaths[currentExo](seed);

  return (
    <Container maxWidth="sm">
      <Alert severity="info">
        Suite à <a href="https://youtu.be/BrGsCD2nVrk">l'incendie à OVH</a>, la version Python de Mathexogen est indisponible.
        Ceci est la version JavaScript, qui était assez inachevée mais qui a l'avantage de pouvoir fonctionner sans mon défunt serveur.
      </Alert>
      <Alert severity="info">
        <a href="https://gitlab.com/cedricvanrompay/mathexogen">voir le code source de mathexogen</a>
      </Alert>
      <header>
        <Typography variant="h3" component="h1" className={classes.h1}>Mathexogen</Typography>
      </header>
      <ExoSelect
        currentExoKey={currentExo}
        onChange={changeCurrentExo}
        exercises={exoPaths}
      />
      {Instructions}
      <div className={classes.buttonDiv}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowSolution(!showSolution)}
        >
          Solution
          </Button>
        <Button
          variant="contained"
          onClick={regenerate}
        >
          Regénérer
          </Button>
      </div>
      {
        showSolution &&
        Solution
      }
    </Container>
  );
}

export default App;
