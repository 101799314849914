import React from 'react';
import TeX from '@matejmazur/react-katex';

import {
  Equality,
  stringify,
} from '../../utils/math';


export function exoComponents({ left, right, unknown, solution}) {
  // we compute these values *out* of the component
  // because they are constant
  const i = stringify(Equality(left, right));
  const s = stringify(Equality(unknown, solution));

  return {
    Instructions: <p><TeX math={i} block/></p>,
    Solution: <p><TeX math={s} block/></p>
  }
}