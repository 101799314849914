import React from 'react';
import TeX from '@matejmazur/react-katex';

import {
  evaluate,
  stringify,
} from '../../utils/math';


export function exoComponents(calculation) {
  const solution = evaluate(calculation);

  // we compute these values *out* of the component
  // because they are constant
  const i = stringify(calculation);
  const s = stringify(solution);

  return {
    Instructions: <p><TeX math={i} block/></p>,
    Solution: <p><TeX math={s} block/></p>
  }
}