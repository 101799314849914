/**
 * In this exercice the student has to read the coordinates of 3 points
 * on the graph of a linear function.
 * 
 * We want all 3 points to have integral coordinates
 * and we don't want the function to go out of our (fixed) graph region.
 * 
 * To simplify generation we divide the graph region into 4 quadrants
 * delimited by the diabonals of the graph region,
 * and we make sure that our first point (named "from" in the code)
 * is in the left quadrant.
 * This garantees us that if the function does not go out of the graph region
 * on the right side, then it does not go out on the left side as well,
 * so we only have to worry about the right side.
 */
import React from 'react';
import TeX from '@matejmazur/react-katex';
import Typography from '@material-ui/core/Typography';

import Random from '../utils/Random';

import graph from './utils/graph';
import LinearFunction from './utils/LinearFunction'

// thanks https://stackoverflow.com/a/46528672/3025740
function addBetween(array, elt, last) {
  let i = array.length - 1;

  do {
    array.splice(i, 0, (Boolean(last) && (i === array.length - 1)) ? last : elt);
    i--
  } while (i > 0)

  return array;
}

export default function(seed) {
  const random = new Random(seed);

  const graphRegion = {
    x: {min: 0, max: 15},
    y: {min: 0, max: 10}
  }

  // TODO make components accept points as objects (with x and y)
  // instad of arrays
  const from = {
    x: random.integer({ min: 0, max: 5 })
  }

  // making sure "from" is in the left quadrant
  from.y = random.integer({
    min: from.x * (graphRegion.y.max / graphRegion.x.max),
    max: graphRegion.y.max - from.x * (graphRegion.y.max / graphRegion.x.max)
  })

  // function will be increasing if slope_sign is 1,
  // and decreasing if it is -1
  const slope_sign = (from.y > graphRegion.y.max / 2) ? -1 : 1

  const range_slope_denominator = {
    min: (
      slope_sign === 1
        ? (graphRegion.x.max - from.x) / (graphRegion.y.max - from.y)
        : (graphRegion.x.max - from.x) / from.y
    ),
    max: (graphRegion.x.max - from.x) / 2,
  }

  const slope = [
    null,
    random.integer(range_slope_denominator),
  ]

  const range_slope_numerator = (
    slope_sign === 1
      ? {
        min: 1,
        max: slope[1] * (graphRegion.y.max - from.y) / (graphRegion.x.max - from.x),
      }
      : {
        min: - slope[1] * (from.y) / (graphRegion.x.max - from.x),
        max: -1,
      }
  )

  slope[0] = random.integer(range_slope_numerator)

  const points = [
    from,
    {
      x: from.x + slope[1],
      y: from.y + slope[0],
    },
    {
      x: from.x + 2*slope[1],
      y: from.y + 2*slope[0],
    },
  ]

  return {
    Instructions: (
      <>
        <Typography>
          {"À l'aide du graphe de la fonction "}
          <TeX>{'f'}</TeX>
          {" ci-dessous, lire "}
          {addBetween(
            points.map(({ x }) =>(
              <TeX>{`f(${x})`}</TeX>
            )),
            ', ',
            ', et ',
          )}
          {'.'}
        </Typography>
        {graph([LinearFunction({ from: [from.x, from.y], slope})])}
      </>
    ),
    Solution: (
      <ul>
        {
          points.map(({ x, y }) => (
            <li key={x}><TeX>{`f(${x}) = ${y}`}</TeX></li>
          ))
        }
      </ul>
    )
  }
}