import arithmeticQ1 from './arithmetic/q1';
import arithmeticQ2 from './arithmetic/q2';
import arithmeticZ1 from './arithmetic/z1';
import arithmeticZ2 from './arithmetic/z2';
import equationsDeg1lvl1 from './equations/deg1lvl1';
import equationsDeg1lvl2 from './equations/deg1lvl2';

arithmeticZ1.title = 'Calcul (entiers niveau 1)'
arithmeticZ2.title = 'Calcul (entiers niveau 2)'
arithmeticQ1.title = 'Calcul (fractions niveau 1)'
arithmeticQ2.title = 'Calcul (fractions niveau 2)'

equationsDeg1lvl1.title = 'Équations (degré 1 niveau 1)'
equationsDeg1lvl2.title = 'Équations (degré 1 niveau 2)'

export default {
  arithmeticZ1,
  arithmeticZ2,
  arithmeticQ1,
  arithmeticQ2,
  equationsDeg1lvl1,
  equationsDeg1lvl2,
}
