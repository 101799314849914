import React from 'react';

export default ({ from, to, slope, label }) => function ({ svgCoord, graphRegion, ...props }) {
  if (to !== undefined) {
    slope = [to[1] - from[1], to[0] - from[0]]
  }
  const x_max = graphRegion.x.max
  return (
    <line
      x1={svgCoord.x(0)}
      y1={svgCoord.y(from[1] - (from[0]*slope[0])/slope[1])}
      x2={svgCoord.x(x_max)}
      y2={svgCoord.y(from[1] + ((x_max - from[0])*slope[0])/slope[1])}
      label={label}
      {...props}
    />
  )
}
